import {
    FC,
    Fragment,
    RefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
} from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { SkinVersions } from "@finbackoffice/site-server-core";
import { ModalsContext, useRuntimeConfig } from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import { MenuContext } from "components/menu/Menu";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import { ModalTypes, RouteNames } from "utils/constants";
import styles from "./compact.module.sass";

const CasinoTools = dynamic(() => import("components/menu/casino-tools/CasinoTools"), {
    ssr: false,
});

type MenuScopeType = "default" | "sport" | "casino";

enum MenuButtonsEnum {
    Live = "live",
    Prematch = "prematch",
    BetSlip = "bet_slip",
    Casino = "casino",
    CasinoTools = "casino_tools",
    Slots = "slots",
    LiveCasino = "livecasino",
}

const CONFIGS: Record<SkinVersions, Record<MenuScopeType, MenuButtonsEnum[]>> = {
    [SkinVersions.Xrpbet]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Ollehbet]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Camisa]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Betvivo]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Navy]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Oddsgate]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Betmidas]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Betvivocomz]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Netpix]: {
        default: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
    [SkinVersions.Chronos]: {
        default: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        sport: [
            MenuButtonsEnum.Live,
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.BetSlip,
            MenuButtonsEnum.Casino,
        ],
        casino: [
            MenuButtonsEnum.Prematch,
            MenuButtonsEnum.LiveCasino,
            MenuButtonsEnum.CasinoTools,
            MenuButtonsEnum.Slots,
        ],
    },
};

const Compact: FC = () => {
    const {
        betProcessing,
        betCountChange,
        onBetSlipButtonClickHandler,
        handleLiveButtonClick,
        handleSportButtonClick,
        hasBetSlipError,
        liveButtonForceActive,
        currentModal,
        betItems,
    } = useContext(MenuContext);
    const router = useRouter();
    const { setCurrentModal } = useContext(ModalsContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const casinoToolsModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const casinoScope = useMemo(() => router.pathname.split("/")[2], [router.pathname]);
    const menuScope = useMemo(() => {
        const chunk = router.pathname.split("/")[1] as MenuScopeType;
        if (chunk === "casino" || chunk === "sport") {
            return chunk;
        }
        return "default";
    }, [router.pathname]);

    const onCasinoToolsClick = useCallback(() => {
        if (currentModal === ModalTypes.CASINO_TOOLS) {
            setCurrentModal(null);
        } else {
            casinoToolsModalRef.current?.open();
        }
    }, [currentModal, setCurrentModal]);

    const onCasinoClick = useCallback(() => {
        const isCasinoLobbyView = router.pathname === `/${RouteNames.CASINO}`;
        if (!isCasinoLobbyView) {
            router.push({
                pathname: `/${RouteNames.CASINO}`,
            });
        }
    }, [router]);

    useEffect(() => {
        if ([ModalTypes.CASINO_TOOLS].includes(currentModal || "")) {
            setCurrentModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casinoScope]);

    const renderMenu = useCallback(
        (name: string) => {
            switch (name) {
                case MenuButtonsEnum.Prematch:
                    const pathToPrematch = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}`;
                    const isPrematchView = router.pathname.includes(pathToPrematch);
                    return (
                        <Button
                            key={name}
                            type="button"
                            onClick={handleSportButtonClick}
                            className={classnames(
                                styles.sport,
                                !liveButtonForceActive && isPrematchView && styles.active,
                            )}>
                            <>
                                {isPrematchView &&
                                    (router.pathname.includes("[gameId]") ||
                                        router.pathname.includes("[tournamentId]")) && (
                                        <i className={styles.navigateTo} />
                                    )}
                                <p>
                                    <Translate tid="pageName_sport" />
                                </p>
                                <Svg
                                    src="/common/mobile/base-icons/menu-sport.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.LiveCasino:
                    const liveCasinoViewHref = `/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`;
                    const isLiveCasinoView = router.pathname.includes(liveCasinoViewHref);

                    const handleLiveCasinoClick = () => {
                        if (!isLiveCasinoView || (isLiveCasinoView && router.query.gameId)) {
                            router.push(liveCasinoViewHref);
                        }
                    };
                    return (
                        <Button
                            type="button"
                            onClick={handleLiveCasinoClick}
                            key={name}
                            href={liveCasinoViewHref}
                            className={classnames(
                                styles.liveCasino,
                                isLiveCasinoView && styles.active,
                            )}>
                            <>
                                {isLiveCasinoView && router.query.gameId && (
                                    <i className={styles.navigateTo} />
                                )}
                                <p>
                                    <Translate tid="pageName_livecasino" />
                                </p>
                                <Svg
                                    src="/common/desktop/base-icons/live-casino-icon.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.BetSlip:
                    return (
                        <Button
                            key={name}
                            type="button"
                            onClick={onBetSlipButtonClickHandler}
                            className={classnames(
                                styles.betslip,
                                currentModal === ModalTypes.BET_SLIP && styles.betSlipActive,
                            )}
                            data-testid="open-betslip-button">
                            <div
                                className={classnames(
                                    hasBetSlipError && styles.betslipHasErrorBtn,
                                )}>
                                <Svg src="/common/mobile/base-icons/menu-betslip.svg" />
                                <span className={classnames(betCountChange && styles.animate)}>
                                    {betItems.length}
                                    {betProcessing && (
                                        <span className={styles.spinner}>
                                            <Svg src="/common/desktop/base-icons/spinner.svg" />
                                        </span>
                                    )}
                                </span>
                            </div>
                            <p>
                                <Translate tid="menu_betSlip" />
                            </p>
                        </Button>
                    );
                case MenuButtonsEnum.Slots:
                    const slotsViewHref = `/${RouteNames.CASINO}/${RouteNames.SLOTS}`;
                    const isSlotsView = router.pathname.includes(slotsViewHref);

                    const handleSlotsClick = () => {
                        if (!isSlotsView || (isSlotsView && router.query.gameId)) {
                            router.push(slotsViewHref);
                        }
                    };
                    return (
                        <Button
                            type="button"
                            onClick={handleSlotsClick}
                            key={name}
                            className={classnames(styles.slots, isSlotsView && styles.active)}>
                            <>
                                {isSlotsView && router.query.gameId && (
                                    <i className={styles.navigateTo} />
                                )}
                                <p>
                                    <Translate tid="pageName_slots" />
                                </p>
                                <Svg
                                    src="/common/desktop/base-icons/slots-icon.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.Live:
                    return (
                        <Button
                            key={name}
                            type="button"
                            onClick={handleLiveButtonClick}
                            className={classnames(
                                styles.live,
                                (liveButtonForceActive ||
                                    router.pathname.includes(RouteNames.CALENDAR) ||
                                    router.pathname.includes(
                                        `/${RouteNames.SPORT}/${RouteNames.LIVE}`,
                                    )) &&
                                    styles.active,
                            )}>
                            <>
                                {router.pathname.includes("[...liveGameRoutes]") && (
                                    <i className={styles.navigateTo} />
                                )}
                                <p>
                                    <Translate tid="pageName_live" />
                                </p>
                                <Svg
                                    src="/common/mobile/base-icons/menu-live.svg"
                                    wrapper="span"
                                    className="svg-icon"
                                />
                            </>
                        </Button>
                    );
                case MenuButtonsEnum.Casino:
                    return (
                        <Button
                            key={name}
                            type="button"
                            onClick={onCasinoClick}
                            className={classnames(
                                styles.casino,
                                router.pathname.includes(`/${RouteNames.CASINO}`) && styles.active,
                            )}>
                            {router.pathname.includes(`/${RouteNames.CASINO}/`) &&
                                router.query.gameId && <i className={styles.navigateTo} />}
                            <p>
                                <Translate tid="menu_casino" />
                            </p>
                            <Svg
                                src="/common/mobile/base-icons/menu-casino.svg"
                                wrapper="span"
                                className="svg-icon"
                            />
                        </Button>
                    );
                case MenuButtonsEnum.CasinoTools:
                    return (
                        <Fragment key={name}>
                            <Button
                                type="button"
                                onClick={onCasinoToolsClick}
                                className={classnames(
                                    styles.casinoButton,
                                    currentModal === ModalTypes.CASINO_TOOLS &&
                                        styles.casinoButtonActive,
                                )}>
                                <div>
                                    <Svg
                                        src="/common/mobile/base-icons/providers.svg"
                                        className={styles.providers}
                                        wrapper="span"
                                    />
                                    <Svg src="/common/mobile/base-icons/menu-betslip.svg" />
                                    <span className={styles.arrow} />
                                    <Svg
                                        src="/common/mobile/base-icons/search.svg"
                                        className={styles.search}
                                        wrapper="span"
                                    />
                                </div>
                                <p>
                                    <Translate tid="menu_casino" />
                                </p>
                            </Button>
                        </Fragment>
                    );
                default:
                    return null;
            }
        },
        [
            router,
            handleSportButtonClick,
            liveButtonForceActive,
            onBetSlipButtonClickHandler,
            currentModal,
            hasBetSlipError,
            betCountChange,
            betItems.length,
            betProcessing,
            handleLiveButtonClick,
            onCasinoClick,
            onCasinoToolsClick,
        ],
    );

    return (
        <>
            {CONFIGS[COMMON_SITE_CONFIGS.skinVersion as SkinVersions][menuScope].map(renderMenu)}
            <Modal
                ref={casinoToolsModalRef}
                type={ModalTypes.CASINO_TOOLS}
                animateVariant="bottom"
                styleClass={styles.menuCasinoModal}>
                <CasinoTools />
            </Modal>
        </>
    );
};

export default Compact;
