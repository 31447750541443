var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6321e5cb976589c7d8589365c4e90e3cb458b46e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const CI_ENVIRONMENT_NAME = window.__NEXT_DATA__.props.pageProps.runtimeConfig.CI_ENVIRONMENT_NAME;
const SENTRY_DSN = window.__NEXT_DATA__.props.pageProps.runtimeConfig.SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN,
    enabled: CI_ENVIRONMENT_NAME !== "local",
    environment: CI_ENVIRONMENT_NAME,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0,
    ignoreErrors: ["AxiosError: Request failed with status code 401"],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
