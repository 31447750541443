import { useSiteConfig } from "@finbackoffice/site-core";
import { ISiteConfigLayouts, ProfileLayoutSectionEnum } from "@finbackoffice/fe-core";
import { useMemo } from "react";

export const useLayoutProfileConfig = (
    name?: ProfileLayoutSectionEnum,
    nestedSectionName?: ProfileLayoutSectionEnum,
) => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");

    const sectionConfig = useMemo(() => {
        return siteLayoutsConfig.profile.config.find((section) => section.name === name) || null;
    }, [name, siteLayoutsConfig.profile.config]);

    const nestedSectionConfig = useMemo(() => {
        return sectionConfig?.config?.find((section) => section.name === nestedSectionName);
    }, [nestedSectionName, sectionConfig?.config]);

    return {
        allConfigs: siteLayoutsConfig.profile.config,
        config: nestedSectionName ? nestedSectionConfig : sectionConfig,
    };
};
