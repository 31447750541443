import React, { FC, useMemo } from "react";
import classnames from "classnames";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { NotificationAPI } from "rc-notification";
import Img from "components/base/img/Img";
import { INoticeEvent, INoticeEventVariant } from "contexts";
import Button from "../button/Button";
import Translate from "../translate/Translate";
import styles from "./site-notification.module.sass";

export type ISiteNotificationVariant = "default" | "modal";
export interface ISiteNotificationProps extends INoticeEvent {
    wrapperClassname?: string;
    variant?: INoticeEventVariant;
    instance?: NotificationAPI;
}

const SiteNotification: FC<ISiteNotificationProps> = ({
    type,
    title,
    message,
    variant = "default",
    instance,
    wrapperClassname,
}) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");

    const renderContent = useMemo(() => {
        if (variant === "modal") {
            return (
                <>
                    <div>{title}</div>
                    <Translate text={message} dangerous>
                        <p />
                    </Translate>
                    <Button type="button" variant="primary" onClick={() => instance?.close(title)}>
                        <Translate tid="base_close" />
                    </Button>
                </>
            );
        }
        return (
            <div className={classnames(styles.notification, styles[type], wrapperClassname)}>
                {title && (
                    <h3>
                        <Img
                            source={`${ASSETS_URL}/common/desktop/base-icons/notification-${type}.svg`}
                            alt={type}
                            width={14}
                            height={14}
                        />
                        {title}
                    </h3>
                )}
                {message && <span>{message}</span>}
            </div>
        );
    }, [ASSETS_URL, instance, message, title, type, variant, wrapperClassname]);

    return renderContent;
};

export default SiteNotification;
