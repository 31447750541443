import { ILayoutsCasinoSections, ISiteConfigLayouts } from "@finbackoffice/fe-core";
import { useSiteConfig } from "@finbackoffice/site-core";
import { useMemo } from "react";

export const useLayoutCasinoConfig = (section: keyof ILayoutsCasinoSections) => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");

    const sectionConfig = useMemo(() => {
        return siteLayoutsConfig._casino.sections[section];
    }, [section, siteLayoutsConfig._casino.sections]);

    return {
        allConfigs: siteLayoutsConfig._casino.sections,
        config: sectionConfig,
    };
};
